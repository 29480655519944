<template>
	<div>
		<usersDetail />
	</div>
</template>

<script>
import usersDetail from '@/components/users-detail'

export default {
	name: 'usersDetailView',
	components: {
		usersDetail,
	},
	computed: {},
}
</script>