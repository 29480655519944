<template>
    <a-spin :tip="spinnerLoaderLabel" size="small" :spinning="spinnerLoader">
        <div class="row">
            <div class="col-md-4">
                <b>Periodo</b>
                <a-range-picker style="width: 100%" @change="getSchedulesReport" v-model="defaultDate" :default-value="defaultDate" :format="dateFormat" />
            </div>
            <div class="col-md-8 text-right mt20">
                <a-button class="btn btn-success" icon="reload" @click="initModule" />
            </div>
        </div>
        <div class="row pt10" v-if="scheduleReport.length">
            <div class="col-md-12">
                <table class="table table-bordered table-condensed table-striped m0 tablePadding25">
                    <tbody>
                        <tr v-for="elementRow, indexRow in scheduleReport" :key="indexRow">
                            <td>
                                <h3 v-if="viewType != 'individual'">{{ elementRow.name }}</h3>
                                <div class="row">
                                    <div class="col-md-2 text-center scheduleHeader">
                                        Fecha
                                    </div>
                                    <div class="col-md-2 text-center scheduleHeader">
                                        Entrada
                                    </div>
                                    <div class="col-md-2 text-center scheduleHeader">
                                        Comida - Salida
                                    </div>
                                    <div class="col-md-2 text-center scheduleHeader">
                                        Comida - Regreso
                                    </div>
                                    <div class="col-md-2 text-center scheduleHeader">
                                        Salida
                                    </div>
                                </div>
                                <div v-for="day, dayIndex in totalDays" :key="dayIndex">
                                    <div class="row" v-if="moment(defaultDate[0]).add(dayIndex, 'days').day() > 0">
                                        <div class="col-md-2 text-center">
                                            {{ moment(defaultDate[0]).add(dayIndex, 'days').format('DD-MM-YYYY') }}
                                        </div>
                                        <div class="col-md-2 text-center">
                                            <div v-if="moment(defaultDate[0]).add(dayIndex, 'days').day() == 0">
                                                --
                                            </div>
                                            <div v-else>
                                                <div v-if="getReport(elementRow.elements, moment(defaultDate[0]).add(dayIndex, 'days').format('YYYY-MM-DD'), 1)">
                                                    <div style="color: red;" v-if="checkDelay(getReport(elementRow.elements, moment(defaultDate[0]).add(dayIndex, 'days').format('YYYY-MM-DD'),
                                                        1), elementRow.max_entry_time).isDelay">
                                                        {{ getReport(elementRow.elements, moment(defaultDate[0]).add(dayIndex, 'days').format('YYYY-MM-DD'), 1) }} hrs.
                                                    </div>
                                                    <div v-else>
                                                        {{ getReport(elementRow.elements, moment(defaultDate[0]).add(dayIndex, 'days').format('YYYY-MM-DD'), 1) }} hrs.
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <span class="pointer" @click="addSchedule(moment(defaultDate[0]).add(dayIndex, 'days').format('YYYY-MM-DD'), elementRow.user_id)">---</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2 text-center">
                                            <div v-if="moment(defaultDate[0]).add(dayIndex, 'days').day() == 0">
                                                --
                                            </div>
                                            <div v-else>
                                                <div v-if="getReport(elementRow.elements, moment(defaultDate[0]).add(dayIndex, 'days').format('YYYY-MM-DD'), 2)">
                                                    {{ getReport(elementRow.elements, moment(defaultDate[0]).add(dayIndex, 'days').format('YYYY-MM-DD'), 2) }} hrs.
                                                </div>
                                                <div v-else>
                                                    ---
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2 text-center">
                                            <div v-if="moment(defaultDate[0]).add(dayIndex, 'days').day() == 0">
                                                --
                                            </div>
                                            <div v-else>
                                                <div v-if="getReport(elementRow.elements, moment(defaultDate[0]).add(dayIndex, 'days').format('YYYY-MM-DD'), 3)">
                                                    {{ getReport(elementRow.elements, moment(defaultDate[0]).add(dayIndex, 'days').format('YYYY-MM-DD'), 3) }} hrs.
                                                    <div v-if="checkMealDelay(getReport(elementRow.elements, moment(defaultDate[0]).add(dayIndex, 'days').format('YYYY-MM-DD'), 2),
                                                        getReport(elementRow.elements, moment(defaultDate[0]).add(dayIndex, 'days').format('YYYY-MM-DD'), 3)).isDelay">
                                                        <div style="color: red; font-size: 10px;">{{ checkMealDelay(getReport(elementRow.elements, moment(defaultDate[0]).add(dayIndex,
                                                            'days').format('YYYY-MM-DD'), 2),
                                                            getReport(elementRow.elements, moment(defaultDate[0]).add(dayIndex, 'days').format('YYYY-MM-DD'), 3)).delay }} mins.</div>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    ---
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2 text-center">
                                            <div v-if="moment(defaultDate[0]).add(dayIndex, 'days').day() == 0">
                                                --
                                            </div>
                                            <div v-else>
                                                <div v-if="getReport(elementRow.elements, moment(defaultDate[0]).add(dayIndex, 'days').format('YYYY-MM-DD'), 4)">
                                                    {{ getReport(elementRow.elements, moment(defaultDate[0]).add(dayIndex, 'days').format('YYYY-MM-DD'), 4) }} hrs.
                                                </div>
                                                <div v-else>
                                                    ---
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-else>
                                        <div class="col-md-2 text-center">
                                            {{ moment(defaultDate[0]).add(dayIndex, 'days').format('DD-MM-YYYY') }}
                                        </div>
                                        <div class="col-md-8 text-center">
                                            <b>-- Domingo --</b>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3 text-center scheduleHeader">
                                        <div style="color: #f5222e;">Faltas</div>
                                        <div>{{ elementRow.faults | replaceIfLogicalFalse('-') }}</div>
                                    </div>
                                    <div class="col-md-3 text-center scheduleHeader">
                                        <div style="color: #ff6400;">Retardos</div>
                                        <div>{{ elementRow.time_delay | replaceIfLogicalFalse('-') }}</div>
                                    </div>
                                    <div class="col-md-3 text-center scheduleHeader">
                                        <div style="color: green;">Bono por puntualidad</div>
                                        <div>{{ elementRow.faults == 0 && elementRow.time_delay == 0 ? 'Si' : 'No' }}</div>
                                    </div>
                                    <div class="col-md-3 text-center scheduleHeader">
                                        <div style="color: green;">Bono por asistencia</div>
                                        <div>{{ elementRow.faults > 0 ? 'No' : 'Si' }}</div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </a-spin>
</template>
<script>
import { mapGetters } from 'vuex'
import numeral from 'numeral'
import _ from 'lodash'
import moment from 'moment-timezone'
import Swal from 'sweetalert2'
import utilities from '@/services/utilities'
//
const dateFormat = 'YYYY-MM-DD'
moment.tz.setDefault('America/Mexico_City')

export default {
    name: 'attendanceRecordComponent',
    props: {
        viewType: {
            type: String,
            default: 'all',
        },
    },
    computed: {
        ...mapGetters('schedules', ['spinnerLoaderLabel', 'spinnerLoader']),
        totalDays() {
            return moment(this.defaultDate[1]).diff(moment(this.defaultDate[0]), 'days') + 1
        },
    },
    data() {
        return {
            monthSelected: moment().format('MM').toString(),
            actualMonth: moment().format('MM').toString(),
            dateFormat,
            defaultDate: [moment().startOf('month').format(dateFormat), moment(moment().startOf('month').add(13, 'days')).format(dateFormat)],
            scheduleReport: [],
        }
    },
    mounted() {
        this.initModule()
    },
    methods: {
        numeral,
        moment,
        initModule() {
            this.getSchedulesReport()
        },
        addSchedule(selectedDate, user_id) {
            if (moment(selectedDate).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')) {
                Swal.fire({
                    title: 'Registro de Asistencia',
                    text: 'Ingresa el horario de ingreso',
                    input: 'text',
                    showCancelButton: true,
                    confirmButtonText: 'Continuar',
                    cancelButtonText: 'Cancelar',
                    confirmButtonColor: '#41b883',
                    cancelButtonColor: '#f5222e',
                    reverseButtons: true,
                    inputAttributes: {
                        autocorrect: 'off',
                        autocomplete: 'off',
                    },
                    inputValidator: (value) => {
                        return new Promise((resolve) => {
                            if (!value) {
                                resolve('Ingresa el horario de ingreso')
                            }
                            resolve()
                        })
                    },
                }).then((result) => {
                    if (result.isConfirmed) {
                        if (!moment(result.value, "HH:mm", true).isValid()) {
                            this.addSchedule(userselectedDate, user_id)
                        } else {
                            this.$store.dispatch('schedules/CREATE_SCHEDULE', {
                                lat: '',
                                lon: '',
                                identifier: `PANEL_${user_id}`,
                                schedule_key: 'schedule_1',
                                version: '',
                                date: selectedDate,
                                entry_time: `${result.value}:00`,
                            }).then(() => {
                                this.getSchedulesReport()
                            })
                        }
                    }
                })
            } else {
                Swal.fire({
                    title: 'Registro de Asistencia',
                    text: 'No puedes generar registro de un día que no sea el actual',
                })
            }
        },
        checkDelay(start, end) {
            const format = 'hh:mm:ss';

            var mStart = moment(start, format)
            var mEnd = moment(end, format)

            return {
                isDelay: mStart.isAfter(mEnd),
                delay: mStart.diff(mEnd, 'minutes'),
            }
        },
        checkMealDelay(start, end) {
            const format = 'hh:mm';

            var mStart = moment(start, format).add(1, 'hour')
            var mEnd = moment(end, format)

            return {
                isDelay: mEnd.isAfter(mStart),
                delay: mEnd.diff(mStart, 'minutes'),
            }
        },
        getSchedulesReport() {
            this.scheduleReport = []
            let payload = {
                date: this.defaultDate,
            }

            if (this.viewType == 'individual') {
                payload.user_id = this.$route.params.id
            }

            this.$store.dispatch('schedules/GET_REPORT', payload).then(response => {
                // console.log('response-->', response);
                let scheduleReport = []
                Object.keys(response.data).forEach(key => {
                    scheduleReport.push(response.data[key])
                })
                // console.log('scheduleReport-->', scheduleReport);
                this.scheduleReport = _.cloneDeep(scheduleReport)
            })
        },
        onChangeTab(key) {
            this.activeTab = key
        },
        getReport(sheduleRecord, date, index) {
            if (!utilities.objectValidate(sheduleRecord, date, false)) {
                return false
            }
            let theRecord = sheduleRecord[date].find(e => e.schedule_key == `schedule_${index}`)
            return utilities.objectValidate(theRecord, 'date', false) ? `${theRecord.entry_time}` : false
        },
    },
}
</script>

<style >
.scheduleHeader {
    font-size: 18px;
    font-weight: bold;
}
</style>