var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-spin',{attrs:{"tip":_vm.spinnerLoaderLabel,"size":"small","spinning":_vm.spinnerLoader}},[_c('a-form',{attrs:{"layout":"vertical","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('a-form-item',{attrs:{"label":"Nombre(s)"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'name',
						{
							rules: [
								{
									required: true,
									message: 'Campo requerido.',
								},
							],
						},
					]),expression:"[\n\t\t\t\t\t\t'name',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\tmessage: 'Campo requerido.',\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t],\n\t\t\t\t\t\t},\n\t\t\t\t\t]"}],attrs:{"autocomplete":"off"}})],1)],1),_c('div',{staticClass:"col-md-4"},[_c('a-form-item',{attrs:{"label":"Apellido Paterno"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'first_lastname',
						{
							rules: [
								{
									required: true,
									message: 'Campo requerido.',
								},
							],
						},
					]),expression:"[\n\t\t\t\t\t\t'first_lastname',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\tmessage: 'Campo requerido.',\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t],\n\t\t\t\t\t\t},\n\t\t\t\t\t]"}]})],1)],1),_c('div',{staticClass:"col-md-4"},[_c('a-form-item',{attrs:{"label":"Apellido Materno"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['second_lastname']),expression:"['second_lastname']"}],attrs:{"autocomplete":"off"}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('a-form-item',{attrs:{"label":"Nombre corto"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'username',
						{
							rules: [
								{
									required: true,
									message: 'Campo requerido.',
								},
							],
						},
					]),expression:"[\n\t\t\t\t\t\t'username',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\tmessage: 'Campo requerido.',\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t],\n\t\t\t\t\t\t},\n\t\t\t\t\t]"}],attrs:{"autocomplete":"off"}})],1)],1),_c('div',{staticClass:"col-md-4"},[_c('a-form-item',{attrs:{"label":"Correo"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'email',
						{
							rules: [
								{
									required: true,
									message: 'Campo requerido.',
								},
							],
						},
					]),expression:"[\n\t\t\t\t\t\t'email',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\tmessage: 'Campo requerido.',\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t],\n\t\t\t\t\t\t},\n\t\t\t\t\t]"}]})],1)],1),_c('div',{staticClass:"col-md-4"},[_c('a-form-item',{attrs:{"label":"Teléfono celular"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'mobile_number',
						{
							rules: [
								{
									required: true,
									message: 'Campo requerido.',
								},
							],
						},
					]),expression:"[\n\t\t\t\t\t\t'mobile_number',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\tmessage: 'Campo requerido.',\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t],\n\t\t\t\t\t\t},\n\t\t\t\t\t]"},{name:"mask",rawName:"v-mask",value:('##########'),expression:"'##########'"}],attrs:{"autocomplete":"off"}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('a-form-item',{attrs:{"label":"Contraseña"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'password',
						{
							rules: [
								{
									min: 6,
									message: 'Ingrese contraseña de al menos 6 caracteres.',
								},
								{
									validator: _vm.validateToNextPassword,
								},
							],
						},
					]),expression:"[\n\t\t\t\t\t\t'password',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\tmin: 6,\n\t\t\t\t\t\t\t\t\tmessage: 'Ingrese contraseña de al menos 6 caracteres.',\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\tvalidator: validateToNextPassword,\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t],\n\t\t\t\t\t\t},\n\t\t\t\t\t]"}],attrs:{"autocomplete":"off"}})],1)],1),_c('div',{staticClass:"col-md-4"},[_c('a-form-item',{attrs:{"label":"Confirmar contraseña"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'confirmPassword',
						{
							rules: [
								{
									min: 6,
									message: 'Ingrese contraseña de al menos 6 caracteres.',
								},
								{
									validator: _vm.compareToFirstPassword,
								},
							],
						},
					]),expression:"[\n\t\t\t\t\t\t'confirmPassword',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\tmin: 6,\n\t\t\t\t\t\t\t\t\tmessage: 'Ingrese contraseña de al menos 6 caracteres.',\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\tvalidator: compareToFirstPassword,\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t],\n\t\t\t\t\t\t},\n\t\t\t\t\t]"}],attrs:{"autocomplete":"off"},on:{"blur":_vm.handleConfirmBlur}})],1)],1),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"btnPasswordGenerator"},[_c('a-button',{staticClass:"mr-1 btn btn-info btn-block",attrs:{"icon":"lock"},on:{"click":_vm.onGeneratePassword}},[_vm._v("Generar contraseña")])],1)])]),_c('div',{staticClass:"row"},[(_vm.recordType == 'create')?_c('div',{staticClass:"col-md-4 text-left"},[_c('a-button',{staticClass:"btn btn-warning",attrs:{"icon":"arrow-left"},on:{"click":_vm.onCancel}},[_vm._v("Regresar")])],1):_vm._e(),_c('div',{staticClass:"text-right",class:_vm.recordType == 'create' ? 'col-md-8' : 'col-md-12'},[_c('a-button',{staticClass:"btn btn-success",attrs:{"icon":"save","htmlType":"submit"}},[_vm._v("Guardar")])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }