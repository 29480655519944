<template>
	<div>
		<div class="row">
			<div class="col-lg-4">
				<div class="row">
					<div class="col-md-12">
						<div class="card">
							<div class="card-body">
								<userProfile />
							</div>
						</div>
					</div>
				</div>
				<div class="row" v-if="false">
					<div class="col-md-12">
						<div class="card">
							<div class="card-body">
								<h5 class="mb-4">
									<strong>Evaluación 5s</strong>
								</h5>
								<div class="mb-5">
									<radarChart :chartdata="radarData" :options="radarOptions" :height="200" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row" v-if="false">
					<div class="col-md-12">
						<div class="card">
							<div class="card-body">
								<h5 class="mb-4">
									<strong>Rentabilidad</strong>
								</h5>
								<div class="mb-5">
									<barChart :chartdata="barData" :options="barOptions" :height="200" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-8">
				<div class="card">
					<div class="card-header card-header-flex flex-column">
						<a-tabs defaultActiveKey="1" class="kit-tabs kit-tabs-bold" @change="callbackTabPane">
							<a-tab-pane tab="Información General" key="1" />
							<a-tab-pane tab="Registro de Asistencias" key="2" />
							<a-tab-pane tab="Evaluaciones 5s" key="3" />
							<!-- <a-tab-pane tab="Información Fiscal y Bancaria" key="4" /> -->
							<a-tab-pane tab="Archivo" key="5" />
						</a-tabs>
					</div>
					<div class="card-body">
						<div v-show="activeKey === '1'">
							<insertOrUpdateUsers recordType="update" />
						</div>
						<div v-show="activeKey === '2'">
							<attendanceRecord viewType="individual" />
						</div>
						<div v-show="activeKey === '3'">
							<surveyResultsComponent />
						</div>
						<div v-show="activeKey === '4'">
							<!-- <bankAndFiscalComponent /> -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
//
import { mapGetters } from 'vuex'
import utilities from '@/services/utilities'
import _ from 'lodash'
import { mask } from 'vue-the-mask'
// import usersPermissions from '@/components/users-permissions'
import userProfile from '@/components/userProfile'
import radarChart from '@/components/radarChart'
import barChart from '@/components/barChart'
import insertOrUpdateUsers from '@/components/insertOrUpdateUsers'
import attendanceRecord from '@/components/attendanceRecord'
import surveyResultsComponent from '@/components/surveys/results'

export default {
	name: 'usersDetail',
	components: {
		userProfile,
		radarChart,
		barChart,
		insertOrUpdateUsers,
		attendanceRecord,
		surveyResultsComponent,
	},
	directives: {
		mask,
	},
	data() {
		return {
			form: this.$form.createForm(this),
			radarData: {
				labels: ['SEIRI', 'SEITON', 'SEISO', 'SEIKETSU', 'SHITSUKE'],
				datasets: [
					{
						backgroundColor: 'rgba(179,181,198,0.2)',
						borderColor: 'rgba(179,181,198,1)',
						pointBackgroundColor: 'rgba(179,181,198,1)',
						pointBorderColor: '#fff',
						pointHoverBackgroundColor: '#fff',
						pointHoverBorderColor: 'rgba(179,181,198,1)',
						data: [65, 59, 90, 81, 56],
					},
				],
			},
			radarOptions: {
				legend: {
					display: false,
				},
				tooltips: {
					enabled: false,
				},
				scale: {
					reverse: true,
					ticks: {
						beginAtZero: true,
					},
				},
			},
			barData: {
				labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
				datasets: [
					{
						backgroundColor: [
							'rgba(255, 99, 132, 0.2)',
							'rgba(54, 162, 235, 0.2)',
							'rgba(255, 206, 86, 0.2)',
							'rgba(75, 192, 192, 0.2)',
							'rgba(153, 102, 255, 0.2)',
							'rgba(255, 159, 64, 0.2)',
							'rgba(255, 99, 132, 0.2)',
							'rgba(54, 162, 235, 0.2)',
							'rgba(255, 206, 86, 0.2)',
							'rgba(75, 192, 192, 0.2)',
							'rgba(153, 102, 255, 0.2)',
							'rgba(255, 159, 64, 0.2)',
						],
						borderColor: [
							'rgba(255,99,132,1)',
							'rgba(54, 162, 235, 1)',
							'rgba(255, 206, 86, 1)',
							'rgba(75, 192, 192, 1)',
							'rgba(153, 102, 255, 1)',
							'rgba(255, 159, 64, 1)',
							'rgba(255,99,132,1)',
							'rgba(54, 162, 235, 1)',
							'rgba(255, 206, 86, 1)',
							'rgba(75, 192, 192, 1)',
							'rgba(153, 102, 255, 1)',
							'rgba(255, 159, 64, 1)',
						],
						borderWidth: 1,
						data: [65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40],
					},
				],
			},
			barOptions: {
				legend: {
					display: false,
				},
				tooltips: {
					enabled: false,
				},
				scales: {
					xAxes: [
						{
							stacked: true,
						},
					],
					yAxes: [
						{
							stacked: true,
						},
					],
				},
			},
			activeKey: '1',
		}
	},
	beforeMount() {
		if (this.businessAreasList.length == 0) {
			this.$store.dispatch('businessAreas/GET')
		}
	},
	mounted() {
		let selectedAreas = []
		if (utilities.objectValidate(this.actualUser, 'areas', false)) {
			selectedAreas = this.actualUser.areas.map((element) => {
				return element.id
			})
		}
		this.form.setFieldsValue({
			name: utilities.objectValidate(this.actualUser, 'name', ''),
			first_lastname: utilities.objectValidate(this.actualUser, 'first_lastname', ''),
			second_lastname: utilities.objectValidate(this.actualUser, 'second_lastname', ''),
			username: utilities.objectValidate(this.actualUser, 'username', ''),
			email: utilities.objectValidate(this.actualUser, 'email', ''),
			mobile_number: utilities.objectValidate(this.actualUser, 'mobile_number', ''),
			is_admin: utilities.objectValidate(this.actualUser, 'is_admin', ''),
			areas: selectedAreas,
		})
	},
	computed: {
		...mapGetters('user', ['user']),
		...mapGetters('users', ['usersList']),
		...mapGetters('businessAreas', ['businessAreasList']),
		...mapGetters('users', ['actualUser']),
		isUserAdmin() {
			return !!this.form.getFieldValue('is_admin')
		},
	},

	methods: {
		callbackTabPane(key) {
			this.activeKey = key
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>