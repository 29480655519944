<template>
	<a-spin :tip="spinnerLoaderLabel" size="small" :spinning="spinnerLoader">
		<a-form layout="vertical" :form="form" @submit="handleSubmit">
			<div class="row">
				<div class="col-md-4">
					<a-form-item label="Nombre(s)">
						<a-input v-decorator="[
							'name',
							{
								rules: [
									{
										required: true,
										message: 'Campo requerido.',
									},
								],
							},
						]" autocomplete="off" />
					</a-form-item>
				</div>
				<div class="col-md-4">
					<a-form-item label="Apellido Paterno">
						<a-input v-decorator="[
							'first_lastname',
							{
								rules: [
									{
										required: true,
										message: 'Campo requerido.',
									},
								],
							},
						]" />
					</a-form-item>
				</div>
				<div class="col-md-4">
					<a-form-item label="Apellido Materno">
						<a-input v-decorator="['second_lastname']" autocomplete="off" />
					</a-form-item>
				</div>
			</div>
			<div class="row">
				<div class="col-md-4">
					<a-form-item label="Nombre corto">
						<a-input v-decorator="[
							'username',
							{
								rules: [
									{
										required: true,
										message: 'Campo requerido.',
									},
								],
							},
						]" autocomplete="off" />
					</a-form-item>
				</div>
				<div class="col-md-4">
					<a-form-item label="Correo">
						<a-input v-decorator="[
							'email',
							{
								rules: [
									{
										required: true,
										message: 'Campo requerido.',
									},
								],
							},
						]" />
					</a-form-item>
				</div>
				<div class="col-md-4">
					<a-form-item label="Teléfono celular">
						<a-input v-decorator="[
							'mobile_number',
							{
								rules: [
									{
										required: true,
										message: 'Campo requerido.',
									},
								],
							},
						]" autocomplete="off" v-mask="'##########'" />
					</a-form-item>
				</div>
			</div>
			<div class="row">
				<div class="col-md-4">
					<a-form-item label="Contraseña">
						<a-input-password v-decorator="[
							'password',
							{
								rules: [
									{
										min: 6,
										message: 'Ingrese contraseña de al menos 6 caracteres.',
									},
									{
										validator: validateToNextPassword,
									},
								],
							},
						]" autocomplete="off" />
					</a-form-item>
				</div>
				<div class="col-md-4">
					<a-form-item label="Confirmar contraseña">
						<a-input-password @blur="handleConfirmBlur" v-decorator="[
							'confirmPassword',
							{
								rules: [
									{
										min: 6,
										message: 'Ingrese contraseña de al menos 6 caracteres.',
									},
									{
										validator: compareToFirstPassword,
									},
								],
							},
						]" autocomplete="off" />
					</a-form-item>
				</div>
				<div class="col-md-4">
					<div class="btnPasswordGenerator">
						<a-button icon="lock" class="mr-1 btn btn-info btn-block" @click="onGeneratePassword">Generar contraseña</a-button>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-4 text-left" v-if="recordType == 'create'">
					<a-button class="btn btn-warning" icon="arrow-left" @click="onCancel">Regresar</a-button>
				</div>
				<div :class="recordType == 'create' ? 'col-md-8' : 'col-md-12'" class="text-right">
					<a-button icon="save" class="btn btn-success" htmlType="submit">Guardar</a-button>
				</div>
			</div>
		</a-form>
	</a-spin>
</template>
<script>
//
import { mapGetters } from 'vuex'
import utilities from '@/services/utilities'
import _ from 'lodash'
import { mask } from 'vue-the-mask'

export default {
	name: 'insertOrUpdateUsers',
	directives: {
		mask,
	},
	props: {
		recordType: {
			type: String,
			default: 'create',
		},
	},
	computed: {
		...mapGetters('user', ['user']),
		...mapGetters('users', ['spinnerLoader', 'spinnerLoaderLabel', 'actualUser']),
	},
	data() {
		return {
			form: this.$form.createForm(this),
			submitLabel: '',
			actualRecord: {},
			confirmDirty: false,
		}
	},
	destroyed() {
		this.unsetLocalData()
	},
	async beforeMount() {
		this.actualRecord = utilities.objectValidate(this.actualUser, [], {})
		if (this.recordType == 'update') {
			if (!utilities.objectValidate(this.actualRecord, 'user_id', false)) {
				this.actualRecord = await this.$store.dispatch('users/GET_ONE', this.$route.params.id)
			}
		}
	},
	mounted() {
		this.form.setFieldsValue({
			password: '',
			confirmPassword: '',
		})
	},
	methods: {
		unsetLocalData() {
			this.form.setFieldsValue({
				name: '',
				first_lastname: '',
				second_lastname: '',
				username: '',
				email: '',
				mobile_number: '',
				password: '',
				confirmPassword: '',
			})
		},
		setLocalData() {
			this.form.setFieldsValue({
				name: utilities.objectValidate(this.actualRecord, 'name', ''),
				first_lastname: utilities.objectValidate(this.actualRecord, 'first_lastname', ''),
				second_lastname: utilities.objectValidate(this.actualRecord, 'second_lastname', ''),
				username: utilities.objectValidate(this.actualRecord, 'username', ''),
				email: utilities.objectValidate(this.actualRecord, 'email', ''),
				mobile_number: utilities.objectValidate(this.actualRecord, 'mobile_number', ''),
			})
		},
		onCancel() {
			this.$router.replace('/administracion')
		},
		onGeneratePassword() {
			utilities.passwordGenerator().then((passwd) => {
				this.form.setFieldsValue({ password: passwd, confirmPassword: passwd })
			})
		},
		handleConfirmBlur(e) {
			const value = e.target.value
			this.confirmDirty = this.confirmDirty || !!value
		},
		compareToFirstPassword(rule, value, callback) {
			const form = this.form
			if (value && value !== form.getFieldValue('password')) {
				callback('Las contraseñas deben coincidir.')
			} else {
				callback()
			}
		},
		validateToNextPassword(rule, value, callback) {
			const form = this.form
			if (value && this.confirmDirty) {
				form.validateFields(['confirmPassword'], { force: true })
			}
			callback()
		},
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields(async (err, values) => {
				if (!err) {
					let payload = {
						...values,
					}
					if (this.recordType == 'update') {
						payload.user_id = this.$route.params.id
						await this.$store.dispatch('users/UPDATE', payload).then(() => {
							this.form.setFieldsValue({
								password: '',
								confirmPassword: '',
							})
						})
					} else {
						this.$store.dispatch('users/CREATE', payload)
					}
				}
			})
		},
	},
	watch: {
		actualRecord(newData) {
			if (utilities.objectValidate(newData, 'user_id', false)) {
				this.setLocalData()
			}
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>