<template>
    <a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
        <div class="row pt20">
            <div class="col-md-12">
                <h1 class="text-muted text-center">Evaluaciones 5s</h1>
                <!-- <a-button @click="initModule()">initModule()</a-button> -->
                <a-collapse v-model="activeKey">
                    <a-collapse-panel v-for="element, key in localData" :key="key" :header="`${element.name} - ${element.created_at} hrs.`">
                        <h1 class="text-muted">Evaluación: {{ element.total }} / {{ element.maximum_weight }}</h1>
                        <div class="row">
                            <div class="col-md-12" v-for="section, sectionKey in element.sections" :key="sectionKey">
                                <b>({{ section.total }}) {{ section.name }}</b>
                                <div class="col-md-12" v-for="elementQuestion, questionKey in section.surveyElements" :key="questionKey">
                                    <a-checkbox readonly :checked="!!elementQuestion.answer"></a-checkbox> {{ elementQuestion.question }}
                                </div>
                                <hr>
                            </div>
                        </div>
                    </a-collapse-panel>
                </a-collapse>
            </div>
        </div>
    </a-spin>
</template>

<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import _ from 'lodash'
import utilities from '@/services/utilities'

export default {
    name: 'surveyResults',
    components: {},
    computed: {
        ...mapGetters('surveys', ['spinnerLoader', 'spinnerLoaderLabel', 'actualSurvey']),
        ...mapGetters('users', ['usersList']),
    },
    props: {
    },
    data() {
        return {
            localData: [],
        }
    },
    mounted() {
        this.initModule()
    },
    methods: {
        initModule() {
            this.localData = []

            this.$store.dispatch('surveys/GET_RESULTS_BY_USER', {
                user_id: this.$route.params.id,
            }).then(response => {
                this.localData = _.cloneDeep(utilities.objectValidate(response, 'data', []))
            })
        },
        onCancelSurvey() {
            this.$emit('onCancel')
        },
    },
}
</script>

<style ></style>
