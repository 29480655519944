var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-spin',{attrs:{"tip":_vm.spinnerLoaderLabel,"size":"small","spinning":_vm.spinnerLoader}},[_c('div',{staticClass:"d-flex flex-wrap flex-column align-items-center"},[_c('div',{staticClass:"kit__utils__avatar kit__utils__avatar--size64 mb-3"},[_c('img',{attrs:{"src":_vm.actualRecord.avatar_image ? `${_vm.apiURL}${_vm.actualRecord.avatar_image}` : 'resources/images/avatars/avatar.png',"alt":_vm.actualRecord.name}})]),_c('a-form',{attrs:{"id":"avatarForm","layout":"vertical","form":_vm.form,"enctype":"multipart/form-data"}},[_c('a-form-item',[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'avatarImage',
                    {
                        valuePropName: 'avatarFile',
                        getValueFromEvent: _vm.avatarFileEvents,
                    },
                ]),expression:"[\n                    'avatarImage',\n                    {\n                        valuePropName: 'avatarFile',\n                        getValueFromEvent: avatarFileEvents,\n                    },\n                ]"}],attrs:{"accept":"image/*","name":"file","action":`${_vm.apiURL}/api/users/avatar/${_vm.actualRecord.user_id}`,"show-upload-list":false,"headers":_vm.uploadHeaders,"before-upload":_vm.beforeUpload}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v("Cambiar imagen ")],1)],1)],1)],1),_c('div',{staticClass:"text-dark font-weight-bold font-size-18"},[_vm._v(_vm._s(_vm.actualRecord.name)+" "+_vm._s(_vm.actualRecord.first_lastname)+" "+_vm._s(_vm.actualRecord.second_lastname))]),_c('div',{staticClass:"text-uppercase mb-3"},[_c('i',{staticClass:"fa fa-phone"}),_vm._v(" "+_vm._s(_vm.actualRecord.mobile_number))]),_c('button',{staticClass:"btn btn-warning",class:_vm.$style.btnWithAddon,attrs:{"type":"button"},on:{"click":_vm.onCancel}},[_c('span',{class:_vm.$style.btnAddon},[_c('i',{staticClass:"fa fa-arrow-left",class:_vm.$style.btnAddonIcon})]),_vm._v(" Regresar ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }