<template>
    <a-spin :tip="spinnerLoaderLabel" size="small" :spinning="spinnerLoader">
        <div class="d-flex flex-wrap flex-column align-items-center">
            <div class="kit__utils__avatar kit__utils__avatar--size64 mb-3">
                <img :src="actualRecord.avatar_image ? `${apiURL}${actualRecord.avatar_image}` : 'resources/images/avatars/avatar.png'" :alt="actualRecord.name" />
            </div>
            <a-form id="avatarForm" layout="vertical" :form="form" enctype="multipart/form-data">
                <a-form-item>
                    <a-upload v-decorator="[
                        'avatarImage',
                        {
                            valuePropName: 'avatarFile',
                            getValueFromEvent: avatarFileEvents,
                        },
                    ]" accept="image/*" name="file" :action="`${apiURL}/api/users/avatar/${actualRecord.user_id}`" :show-upload-list="false" :headers="uploadHeaders" :before-upload="beforeUpload">
                        <a-button> <a-icon type="upload" />Cambiar imagen </a-button>
                    </a-upload>
                </a-form-item>
            </a-form>
            <div class="text-dark font-weight-bold font-size-18">{{ actualRecord.name }} {{ actualRecord.first_lastname }} {{ actualRecord.second_lastname }}</div>
            <div class="text-uppercase mb-3"> <i class="fa fa-phone"></i> {{ actualRecord.mobile_number }}</div>
            <button type="button" class="btn btn-warning" :class="$style.btnWithAddon" @click="onCancel">
                <span :class="$style.btnAddon">
                    <i class="fa fa-arrow-left" :class="$style.btnAddonIcon" />
                </span>
                Regresar
            </button>
        </div>
    </a-spin>
</template>

<script>
//
import { mapGetters } from 'vuex'
import utilities from '@/services/utilities'
import _ from 'lodash'
import Vue from 'vue'
import store from 'store'

export default {
    name: 'userProfileComponent',
    computed: {
        ...mapGetters('user', ['user']),
        ...mapGetters('users', ['spinnerLoader', 'spinnerLoaderLabel', 'actualUser']),
    },
    data() {
        return {
            form: this.$form.createForm(this),
            uploadHeaders: {},
            apiURL: process.env.VUE_APP_API_URL,
            actualRecord: {},
        }
    },
    async beforeMount() {
        this.actualRecord = utilities.objectValidate(this.actualUser, [], {})
        if (!utilities.objectValidate(this.actualRecord, 'user_id', false)) {
            this.initModule()
        }
    },
    mounted() {
        //
        let accessToken = store.get('accessToken')
        this.uploadHeaders = {
            Authorization: `Bearer ${accessToken}`,
        }
    },
    methods: {
        async initModule() {
            this.actualRecord = await this.$store.dispatch('users/GET_ONE', this.$route.params.id)
        },
        onCancel() {
            this.$router.replace('/administracion')
        },
        avatarFileEvents(e) {
            if (e.file.status != 'done') {
                this.$store.commit('user/SET_STATE', {
                    loading: true,
                    spinnerLoaderLabel: 'Actualizando avatar...',
                })
            } else {
                if (e.file && e.file.response) {
                    Vue.prototype.$notification.success({
                        message: 'Actualización de datos',
                        description: e.file.response.message || 'Correcto',
                    })
                    this.initModule()
                }
            }
        },
        beforeUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 2
            if (!isLt2M) {
                this.$store.commit('user/SET_STATE', {
                    loading: true,
                    spinnerLoaderLabel: 'Actualizando avatar...',
                })
                Vue.prototype.$notification.error({
                    message: 'Actualización de datos',
                    description: 'La imagen debe ser menor de 2MB',
                })
            }
            return isLt2M
        },
    },
}

</script>

<style lang="scss" module>
@import './style.module.scss';
</style>